<template>
    <div
        :id="id"
        :class="{
            'wit-markdown-editor': true,
        }"
    >
        <textarea v-model="localValue" :disabled="disabled" :placeholder="placeholder" @input="emitInput"></textarea>
        <div tabIndex="0" @click="setFocus" v-html="compiledMarkdown" ref="compiled"></div>
    </div>
</template>

<script>
const MarkdownIt = require('markdown-it')
const debounce = require('lodash.debounce')

export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localValue: '',
            md: new MarkdownIt(),
        }
    },
    computed: {
        compiledMarkdown() {
            return this.localValue ? this.md.render(this.localValue, {sanitize: true}) : ''
        },
    },
    methods: {
        initValue(value) {
            this.localValue = value
        },

        emitInputDebounced() {
            return debounce(() => {
                this.$emit('input', this.localValue)
            }, 300)()
        },

        emitInput() {
            return this.emitInputDebounced()
        },

        setFocus() {
            if (this.$refs.compiled) {
                this.$refs.compiled.focus()
            }
        },
    },
    watch: {
        value(newValue) {
            this.initValue(newValue)
            this.emitInput()
        },
    },
    created() {
        this.initValue(this.value)
    },
}
</script>

<style lang="scss">
.wit-markdown-editor {
    height: fit-content;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    cursor: text;
    padding: 0;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    background-color: white;

    textarea {
        border: 0;
        width: 50%;
        resize: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin: 0.375rem 0.75rem;
        padding: 0;
    }

    textarea:focus {
        outline: 0;
    }

    > div:focus {
        border: 0;
        outline: 0;
    }

    > div {
        width: 50%;
        margin: 0.375rem 0.75rem;
        max-width: 50%;
        overflow-wrap: break-word;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        min-height: fit-content;
        // @include markdown;
    }
}
</style>
